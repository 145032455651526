import React, { useState, lazy } from "react";
import "./Assets/SCSS/main.scss";

import { BrowserRouter, Redirect, Route, PublicPage, Link } from "react-router-dom";
// import { ChatBox } from './Common/ChatBox';
import ScrollToTop from "./ScrollToTop";
import { Helmet } from "react-helmet";
// Parts
import Header from "./Common/header";
import Footer from "./Common/footer";
// End Parts

import GetStarted from "./Containers/GetStarted/index";
import ThankYou from "./Containers/ThankYou/index";
import Finishes from "./Containers/Finishes/index";
import GeoLocation from "./Containers/GeoLocation/";
import PrivacyPolicy from "./Containers/PrivacyPolicy";
import RealEstate from "./Containers/RealEstate";
import Referrals from "./Containers/Referrals/";
import Blog from "./Containers/Blog/";

import {CustomClosetsPage} from "./routes/kitchens/CustomClosetsPage";
import {BlindsPage} from "./routes/kitchens/BlindsPage";

//import PdfDocument from "./Views/bathroomForm";
import setup from "./Views/setup";
import { createTheme as createMuiTheme, ThemeProvider } from "@material-ui/core/styles";

import bathroomRoutes from "./routes/bathrooms";
import kitchenRoutes from "./routes/kitchens";
import basementRoutes from "./routes/basements";
import Promotion from "./routes/bathrooms/Promotion";

import Visualizer from "./Containers/HowItWorks/Visualizer";

const theme = createMuiTheme({
  palette: {
    primary: {
      light: "#46bfee",
      main: "#46bfee",
      dark: "#46bfee",
      contrastText: "#fff",
    },
    secondary: {
      main: "#46bfee",
    },
  },
});



function App(props) {
  const [geoData, setGeoData] = useState(null);

  const appRoutes =
    process.env.REACT_APP_NAME === "kitchens" 
      ? kitchenRoutes 
      : process.env.REACT_APP_NAME === "basements" 
        ? basementRoutes 
        : bathroomRoutes;

  return (
    <BrowserRouter>

      <Helmet>
        <meta name="description" content={process.env.REACT_APP_META_DESCRIPTION} />
        <meta name="keywords" content={process.env.REACT_APP_META_KEYWORDS} />
        <meta name="robots" content="noindex"/>
      </Helmet>

      <ThemeProvider theme={theme}>
        {/* <ScrollToTop>  </ScrollToTop> */}
          <Header isShowlogos={geoData && geoData.countryCode !== "US"} />
          {appRoutes.map((route) => {
            const { name, renderWithData, ...routeProps } = route;
            const renderProp = renderWithData
              ? {
                  render: () =>
                    renderWithData({
                      data: geoData,
                      isKitchenApp: process.env.REACT_APP_NAME === "kitchens",
                      isBathroomApp: process.env.REACT_APP_NAME === "bathrooms",
                      isBasementApp: process.env.REACT_APP_NAME === "basements",
                      location: geoData,
                    }),
                }
              : {};
            return <Route key={name} {...routeProps} {...renderProp}  />;
          })}

          <Route path="*" render={() => <Redirect to="/" />} />

          {/* <Route exact path="/get-started" component={GetStarted} />
          <Route exact path="/thank-you" component={ThankYou} />
          <Route exact path="/finishes" component={Finishes} />
          <Route exact path="/setup" component={setup} />
          <Route exact path="/privacy" component={PrivacyPolicy} />
          <Route exact path="/real-estate" component={RealEstate} />
          <Route exact path="/promotion-agm" component={Promotion} />
          <Route exact path="/referrals" component={Referrals} />
          <Route exact path="/blog" component={Blog} />
          {process.env.REACT_APP_NAME === "basements" 
          ? <>
              <Route exact path="/visualize" component={Visualizer} />
          </>
          : <>
            <Route exact path="/design">
              <PublicPage />
            </Route>
          </>
          }


          {process.env.REACT_APP_NAME === 'kitchens' && (
            <>
              <Route exact path="/custom_closets" component={CustomClosetsPage} />
              <Route exact path="/blinds"         component={BlindsPage} />

              <Link to="/custom_closets" style={{ visibility: 'hidden', position: 'absolute' }}/>
              <Link to="/blinds" style={{ visibility: 'hidden', position: 'absolute' }}/>
            </>
          )}

          <GeoLocation
            data={geoData}
            setGeoData={(geoDatanew) => setGeoData(geoDatanew)}
            updateUserLocation={() => {}}
            userLocationContact={null}
            visible={true}
          />
      
          <Footer data={geoData} /> */}

      
      </ThemeProvider>
    </BrowserRouter>
  );

  function PublicPage() {
    return (window.location = process.env.PUBLIC_URL);
  }
}

export default App;
