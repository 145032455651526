import React from "react";

import { HomePage } from "./HomePage";
import { HowItWorksPage } from "./HowItWorksPage";
import { OurWorkPage } from "./OurWorkPage";
import { AboutUsPage } from "./AboutUsPage";
import { OurNews } from "./OurNews";
import { WarrantyPage } from "./WarrantyPage";
import { CareersPage } from "./CareersPage";
import { WhyAgm } from "../../Containers/WhyAgm";
import { ReviewsPage } from "./ReviewsPage";


export const routes = [
  {
    name: "Home",
    path: "/",
    exact: true,
    renderWithData: HomePage,
  },
  // {
  //   name: "HowItWorks",
  //   path: "/how-it-works",
  //   exact: true,
  //   component: HowItWorksPage,
  // },
  // {
  //   name: "OurWork",
  //   path: "/our-work",
  //   exact: true,
  //   renderWithData: OurWorkPage,
  // },
  // {
  //   name: "AboutUs",
  //   path: "/about-us",
  //   exact: true,
  //   renderWithData: AboutUsPage,
  // },
  // {
  //   name: "News",
  //   path: "/our-news-blog",
  //   exact: true,
  //   renderWithData: OurNews,
  // },
  // {
  //   name: "Warranty",
  //   path: "/warranty",
  //   exact: true,
  //   renderWithData: WarrantyPage,
  // },
  // {
  //   name: "Career",
  //   path: "/careers",
  //   exact: true,
  //   renderWithData: CareersPage,
  // },
  // {
  //   name: "Reviews",
  //   path: "/reviews",
  //   exact: true,
  //   renderWithData: ReviewsPage,
  // },
  // {
  //   name: "Why-AGM",
  //   path: "/why-agm",
  //   exact: true,
  //   renderWithData: WhyAgm,
  // },
];

export default routes;
